import React from "react"
import Helmet from "react-helmet"
import icon from "../../img/favicon.png"

export default ({ title = "", descr = "", slug = "", admin = false }) => {
  return (
    <Helmet>
      <title>
        {title ? `${title} · ETF Stock Report` : `ETF Stock Report · Welcome`}
      </title>
      <meta name="description" content={descr} />
      <meta property="og:url" content={`https://etfstockreport.com${slug}`} />
      <meta
        property="og:title"
        content={title ? `${title} · ETF Stock Report` : `ETF Stock Report`}
      />
      )}
      <meta property="og:description" content={descr} />
      <meta
        property="og:image"
        content="https://etfstockreport.com/og-logo.png"
      />
      <meta property="og:image:alt" content="ETF Stock Report Logo" />
      <meta property="og:type" content="website" />
      <link rel="shortcut icon" href={icon} />
      {admin && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}
