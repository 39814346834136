export const API_URL = "https://api.etfstockreport.com"
/*
  pk_test_51HSlu9GIBfV6IxfhCWVuZuQDTkaGW5Uq9spL7CACNRqSIjhYHbZeHHAYInZbSkmiz04dCz6e1oIud1QrFGZGGZRa00ldkH401G // test publishable key
  pk_live_51HSlu9GIBfV6IxfhMYamFATeQhKauBGth5MtsjSvszanVTIiroNToIB9VowmIiM3smRiI8A0K1WvbCAAMHmXJmTd000fYgXVh4 // live publishable key
*/
export const STRIPE_KEY =
  "pk_live_51HSlu9GIBfV6IxfhMYamFATeQhKauBGth5MtsjSvszanVTIiroNToIB9VowmIiM3smRiI8A0K1WvbCAAMHmXJmTd000fYgXVh4"

export const SUBSCRIPTIONS = [
  {
    value: "individual",
    name: "Individual",
    price: "$50 per month",
    amt: 5000,
  },
]

export function isFieldError(array, val) {
  return array.includes(val)
}

export function createMarkup(html) {
  return { __html: html }
}

const isBrowser = () => typeof window !== "undefined"

export const getValFromLS = (key, useSessionStorage = false) => {
  if (useSessionStorage) {
    return isBrowser() && window.sessionStorage.getItem(key)
      ? window.sessionStorage.getItem(key)
      : undefined
  } else {
    return isBrowser() && window.localStorage.getItem(key)
      ? window.localStorage.getItem(key)
      : undefined
  }
}

export const setValToLS = (key, val, useSessionStorage = false) => {
  if (isBrowser() && val) {
    useSessionStorage
      ? window.sessionStorage.setItem(key, val)
      : window.localStorage.setItem(key, val)
  } else if (isBrowser()) {
    useSessionStorage
      ? window.sessionStorage.removeItem(key)
      : window.localStorage.removeItem(key)
  }
  return true
}

export function fmtCurrency(int) {
  const amt = int
  return `${amt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })}`
}

export function getVal(array, value, key) {
  let val = ""
  array.forEach(item => {
    if (item.value === value) {
      val = item[key]
    }
  })
  return val
}
